<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div class="mt-5">
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <p class="text-title mon-bold">
                  {{ texts.warehouse.textTitle }}
                </p>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <div class="display-flex align-items-center justify-content-flex-end height-100">
                  <v-btn
                    v-if="isResponsive"
                    elevation="0"
                    to="/warehouse/add"
                    class="button-add-warehouse mon-bold"
                    icon
                  >
                    <v-icon size="18px">mdi-plus-thick</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    elevation="0"
                    to="/warehouse/add"
                    class="button-add-warehouse mon-bold"
                  >
                    {{ texts.warehouse.buttonAddWarehouse }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "WarehouseSearchbarLayout",
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  data() {
    return {
      isResponsive: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
  },
};
</script>


<style scoped>
.text-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.button-add-warehouse {
  background: transparent linear-gradient(254deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding-left: 25px;
  padding-right: 25px;
  height: 40px !important;
  font-size: 14px;
  text-transform: initial;
}
</style>